import { combineReducers } from 'redux';

import filtersReducer from '@redux/shared/reducers/filters.reducer';
import createMetadataReducer from '@redux/shared/reducers/createMetadata.reducer';

import { MAP_EDITS_FETCHED } from '@redux/mapEdit/mapEdit.actions';

const all = (state = {}, action) => {
  switch (action.type) {
    case MAP_EDITS_FETCHED:
      return action.payload;
    default:
      return state;
  }
};

const mapEditReducer = combineReducers({
  all,
  filters: filtersReducer('mapEditFilters', {}),
  metadata: createMetadataReducer('mapEdit'),
});

export default mapEditReducer;
